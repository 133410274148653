@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300&family=Inter&family=Montserrat:wght@300;500;700&family=Open+Sans&family=Roboto&family=Sono:wght@200;300&display=swap');

html {
  font-family: 'Montserrat', sans-serif;
  color: #ad8f8f;
  background-color: #f4f1f1;
}

header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  top: 0;
  left: 0;
  height: 40px;
  width: calc(100% - 172px);
  font-weight: 500;
}

footer {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 20px;
}

label {
  margin-right: 1em;
  font-size: large;
  color: #660000;
}

select {
  padding: 3px;
  font-size:medium;
  font-weight: bold;
  margin-right: 3px;
  border-radius: 5px;
  color: #660000;
  background-color: #ad8f8f25;
  font-weight: 500;
}

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1, nav {
  margin: 0;
  padding: 0;
}

p {
  font-family: 'Sono', sans-serif;
  text-shadow: 0 0 1px #00000044;
  font-size: larger;
}

.header-img {
  margin-left: 10px;
  width: 30px;
  border-radius: 100%;
  border: 1px solid #6b515155;
}

.header-email {
  padding-left: 5px;
  padding-right: 15px;
}

.navbar {
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  max-width: 25%;
  margin: 70px 10px 0 50px;
  position: fixed;
}

.navbar-item {
  list-style-type: none;
  font-weight: bold;
  text-decoration: none;
  padding: 8px 0 8px 16px;
  border-left: 0 solid #660000aa;
  font-size: 20px;
  transition: 100ms;
  font-weight: 300;
}

.navbar-item:hover {
  cursor: default;
  border-left: 6px solid #66000099;
  transition: 125ms;
}

.navbar-selected-item {
  font-weight: bolder;
  color: #660000;
  border-left: 6px solid #660000;
  transition: 100ms;
  font-weight: 600;
}

.navbar-selected-item:hover {
  text-decoration: none;
  border-left: 6px solid #660000;
}

.navbar-social {
  display: flex;
  margin-top: 300px;
  justify-content: flex-start;
}

.navbar-social-item {
  color: #6b5151;
  margin-right: 25px;
  transition: 250ms;
}

.navbar-social-item:hover {
  color: #8e0000;
  cursor: pointer;
  transition: 250ms;
}

.illustration-container {
  display: flex;
  justify-content: space-between;
  margin: 50px 0 50px 0;
  width: 100%;
}

.illustration-column {
  margin-left: 20px;
  margin-right: 20px;
  width: 50%;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0 50px 0;
  width: 100%;
}

.container-img {
  opacity: 1;
  object-fit: contain;
  border-radius: 2%;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  min-width: 100px;
  transition-duration: 200ms;
  box-shadow: 2px 2px 5px #00000055;
}

.container-img:hover {
  cursor: pointer;
  opacity: 0.9;
  box-shadow: 2px 2px 5px #6b5151aa;
  transition-duration: 200ms;
}

.centered-big-img {
  position: sticky;
  z-index: 20;
  object-fit: contain;
  max-width:100%;
  max-height:100%;
  box-shadow: 0 0 2px #f4f1f1d9;
}

.me-img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 100%;
  box-shadow: 0 0 4px #000000aa;
}

.contact-img {
  width: 350px;
  object-fit: cover;
  margin-top: 20px;
}

.dimmed-screen {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.paragraph {
  margin-top: 1em;
  line-height: 2em;
  text-align: justify;
}

.footer-paragraph {
  margin-top: 2em;
  line-height: 1em;
  text-align: center;
}

.centered {
  text-align: center;
}

.button-box {
  border: 1px solid #f4f1f1;
  box-shadow: 0 0 3px #f4f1f1d9;
  border-radius: 5%;
  background-color: #f4f1f122;
  padding: 35px;
  opacity: 0.6;
}

.hidden-button-box {
  border: 1px solid #f4f1f1;
  padding: 35px;
  opacity: 0;
}

.button-box:hover {
  cursor: pointer;
}

.change-img-icon {
  color: #f4f1f1;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.black-and-white {
  color: black !important;
  background-color: #f4f4f4 !important;
  border-color: #cccccc !important;
}

i.black-and-white:hover {
  color: gray !important;
}

span.black-and-white {
  filter: grayscale(1) !important;
}

span.black-and-white:hover {
  filter: grayscale(1) !important;
}

.header-img.black-and-white {
  filter: grayscale(1) !important;
}

.navbar-selected-item.black-and-white {
  color: black !important;
  background-color: #f4f4f4 !important;
  border-color: black !important;
}

#main {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

#illustrator-name {
  color: #660000;
  margin-bottom: 2em;
  font-size: xx-large;
  font-weight: 700;
}

#page-content {
  margin-left: 320px;
  margin-right: 150px;
  width: 100%;
}

#back-to-top-icon {
  color: #660000;
  transition-duration: 200ms;
}

#back-to-top-text {
  color: #660000;
  font-size: x-small;
}

#back-to-top-icon:hover {
  color: #aa0000;
  transition-duration: 200ms;
}

@media screen and (max-width: 991px) {
  /* start of large tablet styles */
  
  body {
    margin: 0;
  }

  .navbar {
    display: flex;
    top: 0;
    left: 0;
    flex-direction: column;
    max-width: 25%;
    margin: 70px 0 0 20px;
    position: fixed;
  }

  .navbar-social {
    margin-top: 350px;
  }

  .illustration-container {
    display: flex;
    justify-content: space-between;
    margin: 50px 0 50px 0;
    width: 100%;
  }
  
  .illustration-column {
    width: 100%;
  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0 50px 0;
    width: 100%;
  }
  
  .container-img {
    opacity: 1;
    object-fit: contain;
    border-radius: 2%;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    min-width: 150px;
    transition-duration: 200ms;
    box-shadow: 2px 2px 5px #00000055;
  }

  #page-content {
    margin-left: 235px;
    margin-right: 50px;
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
/* start of phone styles */
  * {
    position: relative;
    max-width: 100vw;
  }

  body {
    margin: 0;
  }

  header {
    margin-bottom: 0;
    position: relative;
    top: 0;
    left: 0;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100vw;
    margin: 0;
    padding: 0;
    font-size: 0;
  }

  select {
    margin-right: 10px;
  }

  ul {
    position: relative;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    width: 100vw;
  }

  p {
    padding-right: 10px;
    padding-left: 10px;
  }

  nav.navbar {
    all: unset;
  }

  .navbar {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-top: 1em;
  }

  .navbar-item {
    padding: 0;
  }

  .navbar-social {
    all: unset;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    margin-top: 1em;
    width: 100vw;
  }

  .navbar-item {
    border-left: unset;
    border-top: 0 solid #66000099;
  }
  
  .navbar-item:hover {
    border-left: unset;
    border-top: 6px solid #66000099;
  }
  
  .navbar-selected-item {
    border-left: unset;
    border-top: 6px solid #660000;
  }
  
  .navbar-selected-item:hover {
    border-left: unset;
    border-top: 6px solid #660000;
  }

  .paragraph {
    line-height: 1.5em;
  }

  .centered {
    text-align: center;
  }

  .illustration-column {
    padding: 0;
    margin: 1%;
  }

  .container-img {
    box-shadow: 1px 1px 3px #00000055;
  }

  #illustrator-name {
    opacity: 0;
    font-size: 0;
    width: 100vw;
    text-align: center;
    color: #660000;
  }

  #page-content {
    position: relative;
    margin: 0;
  }

  #main {
    display: flex;
    flex-direction: column;
  }
}